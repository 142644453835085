import classNames from "classnames";
import { LabelMap } from "data/Sample";
import { createContext, FunctionComponent, HTMLProps, useCallback, useState } from "react";

import './samplebrowser.css';
import { LabelMapView } from "./SampleView";
import { IProjectConnection } from "data/types";



interface SampleBrowserProps{
    samples: LabelMap
    connection: IProjectConnection
}

export const SampleBrowserContext = createContext<{connection: IProjectConnection, searchString: string} | null>(null)

export const SampleBrowser: FunctionComponent<HTMLProps<HTMLDivElement> & SampleBrowserProps> = ({samples, className, connection, style})=>{

    const [searchString, setSearchString] = useState<string>("");

    const setSearch = useCallback((e)=>{setSearchString(e.target.value.toLowerCase())}, [])

    return (
        <div className={classNames('SampleBrowser', className)} style={style}>
            <h2>Sample Browser</h2>
            <input type="text" placeholder="Search" value={searchString} onChange={setSearch}>
            
            </input>
            <SampleBrowserContext.Provider value={{connection, searchString}}>
            <div className="sample-list">
                    <LabelMapView sample={samples}/>
            </div>
            </SampleBrowserContext.Provider>
        </div>
    )
}