import { FunctionComponent } from "react";
import { Route, Switch } from "react-router";
import {
  CollectionPage,
  CollectionFormPage,
  HelpPage,
  SignInPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  AnalysisPage,
  HtmlPage,
} from "pages";
import { UserWrapper } from "components/user";
import { Notifications } from "components/notifications";
// import { ThemeWrapper } from "components/theme";
import "styles/reset.css";
import "styles/globals.css";
import "styles/theme.css";
import "styles/app.css";

/**
 * The main app component which renders all other components.
 * Used to store routing information for each page.
 */
const App: FunctionComponent = () => {
  return (
    <Notifications>
      <UserWrapper>
        {/* A weird error occurs when the theme wrapper is enabled. 
         * In addition, because the iframe display of collection forms 
         * is light, wrapping to enable switch from light to dark mode has been 
         * disabled.
         /}
        {/* <ThemeWrapper> */}
        <Switch>
          {/* Home page route. */}
          <Route exact path="/" component={CollectionPage} />

          {/* Analysis page route. */}
          <Route exact path="/analysis" component={AnalysisPage} />

          {/* HTML page route. */}
          <Route exact path="/html" component={HtmlPage} />

          {/* Help page route. */}
          <Route exact path="/help" component={HelpPage} />

          {/* Forms page route. */}
          <Route exact path="/collection" component={CollectionFormPage} />

          {/* Authentication page routes. */}
          <Route exact path="/signin" component={SignInPage} />
          <Route
            exact
            path="/signin/forgotpassword"
            component={ForgotPasswordPage}
          />
          <Route
            exact
            path="/signin/resetpassword"
            component={ResetPasswordPage}
          />
        </Switch>
        {/* </ThemeWrapper> */}
      </UserWrapper>
    </Notifications>
  );
};

export default App;
