import Sample, { LabelMap } from "./Sample";
import { IConnection, IFormProps, IProjectProps, INode, ICollection, NodeRequest, IProjectConnection } from "./types";

type Method = "GET" | "POST" | "DELETE" | "PUT" 

class CartaObjectStoreException extends Error{

    constructor(public host: string, public url: string, public method: Method, public body?: Object){
        super("An error occurred with the Carta Object Storage API")
    }
}


export interface NodesList{
    nextPageToken: string;
    nodes: INode[]
}

export default class CartaObjectStorage{

    public constructor(private host: string, private token: string, hosted: boolean = false){
        if(hosted){
            this.host = `${this.host}/service/carta/object-storage`;
        }

        if(!this.token.startsWith("Bearer ")){
            this.token = `Bearer ${this.token}`
        }
    }

    private async cartaObjectRequest(method: Method, url: string, body?: Object): Promise<Response> {
        const init = {method} as RequestInit

        init.headers = new Headers(init.headers);
        init.headers.set("Authorization", this.token);

        if(typeof body !== 'undefined'){
            init.headers.set("Content-Type", `application/json`);
            init.body = JSON.stringify(body)
        }

        const full_url = `${this.host}${url}`;

        const response = await fetch(full_url, init);

        if (!response.ok) {
            throw new CartaObjectStoreException(this.host, url, method, body)
        }

        return response;
    }

    // V2 Routes
    async listNodes(connection: NodeRequest, node: INode): Promise<NodesList>{
        const response = await this.cartaObjectRequest('POST', '/api/v2/list-nodes', {
            ...connection,
            node: node
        })

        return await response.json() as NodesList;
    }


    // V1 Routes
    async listProjects(): Promise<IProjectProps[]>{

        const response = await this.cartaObjectRequest('POST', '/project-api/v1/list-projects')
        
        return await response.json() as IProjectProps[];
    }

    async listSchemas(project: IProjectProps): Promise<IFormProps[]>{

        const response = await this.cartaObjectRequest('POST', '/project-api/v1/list-schemas', project)
        
        return await response.json() as IFormProps[];
    }

    async listConnections(project: IProjectProps): Promise<IConnection[]>{
        const response = await this.cartaObjectRequest('POST', '/project-api/v1/list-connections', project)
        
        return await response.json() as IConnection[];
    }

    async getCollection(project: ICollection): Promise<string>{
        const response = await this.cartaObjectRequest('POST', '/project-api/v1/get-collection', project)
        
        let blob = await response.blob();
        blob = blob.slice(0, blob.size, "text/html");
        return URL.createObjectURL(blob);
    }

    async getSamples(_project: IProjectConnection): Promise<LabelMap>{
        const response = await this.cartaObjectRequest('GET', `/project-api/v1/samples/${_project.projectId}/${_project.connectionId}`);
        return Sample.fromSampleList(await response.json())
    }
    
}
