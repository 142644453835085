import { FunctionComponent, useEffect, useState } from "react";
import { CognitoPasswordPolicy } from "library/api";
import { TextFieldInput } from "components/input";
import { Text } from "components/text";
import { Tooltip } from "components/tooltip";
import {config } from "library/api";
import styles from "./UserAuthDialog.module.css";

/** The props used for the {@link UserPasswordValidation} component. */
interface UserValidatedPasswordInputProps {
  /** The password text. */
  password: string;
  /** The password copy text. */
  passwordCopy: string;
  /** Event handler for handling a change in password input. */
  onPasswordChanged?: (name: string) => void;
  /** Event handler for handling a change in password copy input. */
  onPasswordCopyChanged?: (name: string) => void;
}

/** A component that renders an input field for a new password,
 * and that validates that the password conforms to policy */
const UserValidatedPasswordInput: FunctionComponent<
  UserValidatedPasswordInputProps
> = ({
  password,
  passwordCopy,
  onPasswordChanged = () => {},
  onPasswordCopyChanged = () => {},
}) => {
  // The password policy to validate the password against
  const [passwordPolicy, setPasswordPolicy] =
    useState<CognitoPasswordPolicy | null>(null);

  // A list of password validation errors
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  // Password copy error
  const [passwordCopyError, setPasswordCopyError] = useState<string | null>(
    null
  );

  // When this component is mounted, we retrieve the password policy
  useEffect(() => {
    const fetchPolicy = async () => {
      setPasswordPolicy((await config).passwordPolicy);
    };
    fetchPolicy();
  }, []);

  // This handles when the user has entered a new password
  const handlePasswordValidation = () => {
    let errors: string[] = [];
    if (passwordPolicy !== null) {
      if (password.length < passwordPolicy.minimumLength) {
        errors.push(
          "Password must be at least " +
            passwordPolicy.minimumLength +
            " characters."
        );
      }
      if (
        passwordPolicy.requireLowercase &&
        password.toUpperCase() === password
      ) {
        errors.push("Password must have at least 1 lowercase letter.");
      }
      if (
        passwordPolicy.requireUppercase &&
        password.toLowerCase() === password
      ) {
        errors.push("Password must have at least 1 uppercase letter.");
      }
      if (passwordPolicy.requireNumbers && password.search(/[0-9]/) < 0) {
        errors.push("Password must have at least 1 number.");
      }
      if (
        passwordPolicy.requireSymbols &&
        password.search(/[ `!@#$ %^&*()_+\-=[\]{};’:”\\|,.<>/? ~]/) < 0
      ) {
        errors.push("Password must have at least 1 symbol.");
      }
    }
    setPasswordErrors(errors);
  };

  // This handles when the user has entered the password copy
  const handlePasswordCopy = () => {
    if (password !== passwordCopy) {
      setPasswordCopyError("Passwords do not match.");
    } else {
      setPasswordCopyError(null);
    }
  };

  return (
    <>
      <label>
        <span>New Password</span>
        <Tooltip
          component={passwordErrors.map((errorMsg) => (
            <Text color="error" key={errorMsg}>
              {errorMsg}
            </Text>
          ))}
          hide={passwordErrors.length === 0}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={password}
            onChange={onPasswordChanged}
            onBlur={handlePasswordValidation}
            placeholder="New Password"
            password
          />
        </Tooltip>
      </label>
      <label className={styles.dialogField}>
        <span>Confirm New Password</span>
        <Tooltip
          component={
            <Text color="error" key="passwordCopyError">
              {passwordCopyError}
            </Text>
          }
          hide={passwordCopyError === null}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={passwordCopy}
            onChange={onPasswordCopyChanged}
            onBlur={handlePasswordCopy}
            placeholder="New Password"
            password
          />
        </Tooltip>
      </label>
    </>
  );
};

export default UserValidatedPasswordInput;
