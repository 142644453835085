import { Auth } from "aws-amplify";
import CartaObjectStorage from "data/CartaObjectStorage"
import { config } from "library/api"
import { useState } from "react";


export default function useCartaObjectStorage() {

    const [cartaObjectStorage] = useState<Promise<CartaObjectStorage>>((async ()=>{

        const host = (await config).host;
        const isDev = (await config).isDev;
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        
        return new CartaObjectStorage(host, idToken, !isDev);
    })());

    return cartaObjectStorage;
}