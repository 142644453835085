import { Auth } from "aws-amplify";
import { FunctionComponent, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { Text } from "components/text";
import { UserNeedsAuthentication } from "components/user";
import { config } from "library/api";

const HtmlFrame: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [url, setUrl] = useState("");
  const location = useLocation();
  useEffect(() => {
    (async () => {
      try {
        const idToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        const host = (await config).host;
        const key = new URLSearchParams(location.search).get("key");
        const urlString = `${host}/files/Carta/file/${key}`;
        const res = await fetch(urlString, {
          method: "GET",
          headers: { Authorization: `Bearer ${idToken}` },
        });
        if (!(await res.ok)) {
          setError(`An unexpected API error occurred. 
            Request: 'GET ${urlString}'. 
            Status code:  '${res.status}'.`);
        } else {
          let blob = await res.blob();
          blob = blob.slice(0, blob.size, "text/html");
          setUrl(URL.createObjectURL(blob));
        }
      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("An unknown error occurred.");
      }
    })();
  }, [location]);

  if (error) {
    return (
      <Text color="error" size="small" padding="center">
        {error}
      </Text>
    );
  } else {
    return (
      <iframe
        style={{
          width: "100%",
          height: `${window.innerHeight}px`,
          overflow: "visible",
          margin: "0",
        }}
        title="html"
        src={url}
        sandbox="allow-scripts allow-forms allow-same-origin allow-top-navigation allow-modals"
        frameBorder="0"
      />
    );
  }
};

const HtmlPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>
        {authenticated ? <HtmlFrame /> : <UserNeedsAuthentication />}
      </Wrapper>
    </PageLayout>
  );
};

export default HtmlPage;
