import { CognitoPasswordPolicy } from "./CognitoTypes";

const config = import("../../deploy.config.json") as Promise<{
  host: string;
  isDev: boolean;
  userPool: {};
  passwordPolicy: CognitoPasswordPolicy;
}>;

export default config;
