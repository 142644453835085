import { FunctionComponent, useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { Text } from "components/text";
import { UserNeedsAuthentication } from "components/user";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";
import { NavigationLink } from "components/navigation";
import { Button } from "components/buttons";
import QrCodeIcon from "components/icons/QrCodeIcon";
import QRCode from "react-qr-code";

const enabledButtonStyle = {
  color: "white",
  backgroundColor: "#6c757d",
  borderColor: "#6c757d",
  padding: ".25em",
};

const CollectionForm: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [url, setUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [nativeId, setNativeId] = useState("");
  const formFrame = useRef<HTMLIFrameElement>(null);
  const [formFrameHeight, setFormFrameHeight] = useState("0px");
  const location = useLocation();
  const qrcode = useRef<HTMLDivElement>(null)
  const cartaObjectStorage = useCartaObjectStorage();
  const onFormFrameLoad = ()=>{
    const formHeight = (formFrame.current?.contentWindow?.document.body.scrollHeight ?? 0) + 1;
    setFormFrameHeight(`${formHeight}px`)
  }

  const openQrCode = ()=>{
    const qrWindow = window.open('', '', 'width=600,height=400,top=0,left=0');
    if(!qrWindow){
      // Unable to create QR code window
      return;
    }
    qrWindow.document.write(qrcode.current?.innerHTML ?? "");
    qrWindow.document.close();
  }

  useEffect(() => {
    (async () => {
      try {
        const _search = new URLSearchParams(location.search);
        const projectId = _search.get("projectId");
        const key = _search.get("key");
        const connectionId = _search.get("connectionId");
        const nativeId = _search.get("nativeId");
        const parentNativeId = _search.get("parentNativeId");

        let collectionProps;

        if (projectId) {
          setProjectId(projectId)
          if (key) {
            collectionProps = { projectId: projectId, key: key };
          } else if (connectionId && nativeId) {
            setNativeId(nativeId);
            collectionProps = {
              projectId: projectId,
              connectionId: connectionId,
              node: { nativeId: JSON.parse(nativeId) },
              parentNode: {
                nativeId: parentNativeId ? JSON.parse(parentNativeId) : {},
              }
            };
          }
        }

        if(!collectionProps){
          throw new Error("Not enough data to construct collection request.")
        }

        const _cartaObjectStorage = await cartaObjectStorage;
        const getProjectName = _cartaObjectStorage.listProjects()
          .then(projects=>projects.find((_project)=> _project.projectId === projectId))
          .then(project=> setProjectName(project?.name ?? ""))

        const getResourceUrl = _cartaObjectStorage.getCollection(collectionProps)
          .then(resourceUrl=>setUrl(resourceUrl))

        await Promise.all([getProjectName, getResourceUrl])

      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("An unknown error occurred.");
      }
    })();
  }, [location, cartaObjectStorage]);

  if (error) {
    return (
      <Text color="error" size="small" padding="center">
        {error}
      </Text>
    );
  } else {
    return (
      <div style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: "100%"
      }}>
      <h2 style={{
        margin: 4
      }}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <NavigationLink to={`/?projectId=${projectId}`}> {projectName} </NavigationLink>
            {nativeId ? <></> : (<>
              <div style={{ "display": "none" }} ref={qrcode}>
                <QRCode size={256} viewBox={`0 0 256 256`} value={document.location.toString()} />
              </div>
              <Button style={enabledButtonStyle} onClick={openQrCode}>
                <QrCodeIcon />
              </Button></>)}
        </div>
      </h2>
      <iframe
        style={{
          width: "100%",
          flexBasis: formFrameHeight,
          flexGrow: 1,
          overflow: "visible",
          margin: "0",
        }}
        onLoad={onFormFrameLoad}

        ref={formFrame}
        title="collection"
        src={url}
        sandbox="allow-scripts allow-forms allow-same-origin allow-top-navigation allow-modals allow-popups"
        frameBorder="0"
      />
      </div>
    );
  }
};

const CollectionFormPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>
        {authenticated ? <CollectionForm /> : <UserNeedsAuthentication />}
      </Wrapper>
    </PageLayout>
  );
};

export default CollectionFormPage;
