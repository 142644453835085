import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./Dropdown.module.css";
import NavigationLink from "components/navigation/NavigationLink";

interface DropdownItemProps extends HTMLAttributes<HTMLSpanElement>{
  href?: string
}

/** A component that represents an item in a dropdown menu. */
const Item: FunctionComponent<DropdownItemProps> = ({
  children,
  href,
  ...props
}) => {
  return href ? (
    <NavigationLink className={styles.dropdownItemLink} to={href}>
      <span className={styles.dropdownItem} {...props}>
        {children}
      </span>
    </NavigationLink>
  ) : (
    <span className={styles.dropdownItem} {...props}>
      {children}
    </span>
  ) ; 
};

export default Item;
