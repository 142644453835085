import React, { FC, useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button, ButtonGroup } from "components/buttons";
import { TextFieldInput } from "components/input";
import { Link } from "components/link";
import { Text, Title } from "components/text";
import { Tooltip } from "components/tooltip";
import UserContext from "./UserContext";
import UserValidatedPasswordInput from "./UserValidatedPasswordInput";
import styles from "./UserAuthDialog.module.css";

/** The props used for the {@link UserResetPasswordDialog} component. */
interface UserResetPasswordDialogProps {
  /** Whether to show the title of the sign in dialog. */
  showTitle?: boolean;
}

/** A component that renders a user reset password dialog. */
const UserResetPasswordDialog: FC<UserResetPasswordDialogProps> = ({
  showTitle,
}) => {
  // We use the username, verification code and new password to call reset password endpoints.
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");

  // Username and verification code errors
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [codeError, setCodeError] = useState<string | null>(null);

  // Set an error message when the form is submitted and an API error occurs
  const [apiError, setApiError] = useState<string | null>(null);

  // We rely upon the user context to handle reset password requests.
  const { resetPassword } = useContext(UserContext);

  // We use history to go back to the sign in page when password reset is successful
  const history = useHistory();

  // The code may be set in a query parameter
  const location = useLocation();
  useEffect(() => {
    const queryCode = new URLSearchParams(location.search).get("code");
    setCode(queryCode ?? "");
  }, [location]);

  // This handles when the user presses the sign in button.
  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    // Check for valid username and password.
    if (username === "") {
      setUsernameError("Username is required.");
    }
    if (code === "") {
      setCodeError("Verification code is required.");
    }
    if (username === "" || code === "") {
      return;
    }
    try {
      await resetPassword(username, code, password);
      setApiError(null);
      const signinUrl = queryString.stringifyUrl({
        url: "/signin",
        query: { username: username },
      });
      history.push(signinUrl);
    } catch (error: any) {
      setApiError(error.message);
    }
  };

  // This handles when the user has entered a user name
  const handleUsername = () => {
    setUsernameError(null);
  };

  // This handles when the user has entered a verification code
  const handleCode = () => {
    setCodeError(null);
  };

  return (
    <form
      className={styles.dialog}
      onSubmit={(event) => {
        handleForgotPassword(event);
      }}
      noValidate
    >
      {showTitle && <Title>Reset Password</Title>}
      <label className={styles.dialogField}>
        <span>Username</span>
        <Tooltip
          component={
            <Text color="error" key="usernameError">
              {usernameError}
            </Text>
          }
          hide={usernameError === null}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={username}
            onChange={setUsername}
            onBlur={handleUsername}
            placeholder="Username"
          />
        </Tooltip>
      </label>
      <label className={styles.dialogField}>
        <span>Verification Code</span>
        <Tooltip
          component={
            <Text color="error" key="codeError">
              {codeError}
            </Text>
          }
          hide={codeError === null}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={code}
            onChange={setCode}
            onBlur={handleCode}
            placeholder="######"
            password
          />
        </Tooltip>
      </label>
      <Text padding="center">
        If you do not have a code, you can request one{" "}
        <Link to="/signin/forgotpassword">here</Link>.
      </Text>
      <UserValidatedPasswordInput
        password={password}
        passwordCopy={passwordCopy}
        onPasswordChanged={setPassword}
        onPasswordCopyChanged={setPasswordCopy}
      />
      <ButtonGroup stretch>
        <Button type="submit" sizing="bulky" className={styles.dialogButton}>
          Reset Password
        </Button>
      </ButtonGroup>
      {apiError && (
        <Text color="error" size="small" padding="center">
          {apiError}
        </Text>
      )}
    </form>
  );
};

export default UserResetPasswordDialog;
export type { UserResetPasswordDialogProps };
