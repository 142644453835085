import { INode } from "data/types";
import { v4 as uuidv4 } from "uuid";

interface ITreeNodeProps {
  id: string;
  parent_id: string;
  node: INode;
  children: TreeNode[];
}


export default class TreeNode {
  id: string;
  parentId: string | undefined;
  node: INode;
  children: TreeNode[];

  constructor(node_props: INode, parentId : string | undefined = undefined) {
    this.id = JSON.stringify(node_props.nativeId);
    this.parentId = parentId;
    this.node = node_props;
    this.children = [];
  }

  traverse(fn: (node: TreeNode) => void) {
    function goThrough(node: TreeNode) {
      fn(node);
      node.children.forEach((child) => {
        goThrough(child);
      });
    }
    goThrough(this);
  }

  addNode(value: INode, parentId: string): TreeNode {
    const newNode = new TreeNode(
      {
        ...value,
      },
      parentId
    );

    if (this.search(newNode.id) !== null) return this;

    this.traverse((node) => {
      if (node.id === parentId) {
        node.children.push(newNode);
      }
    });
    return this;
  }

  addChildren(values: INode[], parentId: string): TreeNode {
    if (values.length > 0) {
      values.map((value) => this.addNode(value, parentId));
    } else {
      // If a node has no children, add a node that denotes "empty".
      this.addNode(
        {
          name: "empty",
          // Because all node must have an unique ID, generate one.
          nativeId: { fileId: uuidv4() },
          type: "empty",
        },
        parentId
      );
    }
    return this;
  }

  search(id: string): TreeNode | null {
    let returnNode = null;
    this.traverse((node) => {
      if (node.id === id) {
        returnNode = node;
      }
    });
    return returnNode;
  }
}

export type { ITreeNodeProps };
