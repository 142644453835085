import classNames from "classnames";
import { Label, LabelMap } from "data/Sample";
import { FunctionComponent, HTMLProps, useCallback, useContext, useState } from "react";

import './samplebrowser.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { SampleBrowserContext } from "./SampleBrowser";
import NavigationLink from "components/navigation/NavigationLink";


interface SampleViewProps {
    sample: LabelMap;
    //connection: IProjectConnection;
}

interface LabelViewProps {
    labelPk: Label;
}

export const LabelView: FunctionComponent<HTMLProps<HTMLDivElement> & LabelViewProps> = ({ labelPk }) => {
    const [expanded, setExpanded] = useState(false);
    const browserContext = useContext(SampleBrowserContext)

    const expand = useCallback(() => {
        setExpanded((isExpanded) => !isExpanded);
    }, [])

    if(browserContext?.searchString){
        if(labelPk.keywords.indexOf(browserContext.searchString) === -1){
            return (<></>);
        }else{
            if(!expanded){
                setExpanded(true);
            }
        }
    }

    const url = `/collection?projectId=${browserContext?.connection.projectId}&connectionId=${browserContext?.connection.connectionId}`;

    return (<div className={classNames('sample', { "has-children": true })} aria-expanded={expanded} 
    style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gridAutoRows: 'auto', justifyContent: 'start', flex: 1}}>
        <div className="sample-expandable" style={{display: 'grid', gridRow: '1', gridColumn: '1 / 3', gridTemplateColumns: 'subgrid', gridTemplateRows: 'subgrid'}}  onClick={expand}>
            <div className="sample-expand">
                {expanded ? (<ExpandMoreIcon />) : (<ChevronRightIcon />)}
            </div>
            <div className="sample-pk">
                {labelPk.name}
            </div>
        </div>
        <div className="sample-children-count" style={{gridColumn: '2', gridRow: '2'}}>
            {labelPk.instances.length} forms / {labelPk.children.list().length} child samples
        </div>
        {expanded ? (
            <div className="sample-children" style={{gridColumn: '2', gridRow: '3'}}>
                {labelPk.instances.sort((a, b) => a.created.getTime() - b.created.getTime()).map(instance => {
                    return (<div className="sample-instance" key={instance.title}>
                        <NavigationLink to={`${url}&nativeId=${JSON.stringify(instance.nativeId)}`}>
                            <div style={{ display: 'grid', gridTemplateColumns: "auto auto", gridTemplateRows: "auto auto", columnGap: '0.5em', alignContent: 'center' }}>
                                
                                <div style={{gridColumn: '1'}}>
                                    <FileOpenIcon style={{fontSize: '18px', verticalAlign: 'top'}}/>
                                </div>
                                <div style={{gridColumn: '2', gridRow: '1', fontSize: '1rem'}}>
                                    {instance.title}

                                </div>
                                <div style={{
                                    gridColumn: '2', gridRow: '2',
                                    fontSize: '0.8em'
                                }}>{instance.created.toLocaleString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })} {instance.created.toLocaleString(undefined, { timeStyle: 'short', })}</div>
                            </div>
                        </NavigationLink>
                    </div>)
                })}
                <LabelMapView sample={labelPk.children} />
            </div>) : <></>}
    </div>)
}

export const LabelMapView: FunctionComponent<HTMLProps<HTMLDivElement> & SampleViewProps> = ({ sample }) => {
 
    return (<>{
        sample.list().map((pk) => {
            const label = sample.get(pk);
            return (<LabelView key={pk} labelPk={label} />)
        })
    }</>)
    // return (<div className={classNames('sample', {"has-children": sample})} aria-expanded={expanded}>
    //     <div className="sample-pk">
    //         <span onClick={expand}>{sample.label}</span> 
    //         <a href={openSampleLink}><OpenInNewTabIcon/></a>
    //     </div>
    //     <div className="sample-children-count">
    //         {sample.children.length} child samples
    //     </div>
    //     {expanded ? (<div className="sample-children">{sample.children.map((child)=>(<SampleView sample={child} connection={connection}/>))}</div>) : (<></>)}
    // </div>)
}