import {
    FunctionComponent,
    useContext,
} from "react";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { UserNeedsAuthentication } from "components/user";
import { Text } from "components/text";

const HelpPage: FunctionComponent = () => {
    const { authenticated } = useContext(UserContext);

    return (
        <PageLayout header footer>
            <Wrapper>
                {authenticated ? (
                    <Text>
                        If you need to create a new form template or edit an existing form,
                        please contact <a href='mailto: lindsey.kuettner@contextualize.us.com'>Lindsey</a>.
                    </Text>
                ) : (
                    <UserNeedsAuthentication />
                )}
            </Wrapper>
        </PageLayout>
    );
};

export default HelpPage;
