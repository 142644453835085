import { ILabeledRecord, NativeId } from './types'

export interface SampleRecord{
    title: string; 
    nativeStr: string;
    nativeId: NativeId;
    created: Date;
}

export class Label{
    instances: SampleRecord[] = [];
    children: LabelMap = new LabelMap();
    public keywords: string = "";
    public constructor(public name: string){}

    calculateKeywords(): string[]{
        const kws = [this.name, this.children.map((child)=>{
            return child.calculateKeywords().join('');
        }).join('')]

        this.keywords = kws.join('').toLocaleLowerCase();

        return kws;
    }
}

export class LabelMap{
    private _map: RawLabelMap = {};

    get(label: string){
        return this._map[label];
    }

    list(){
        return Object.keys(this._map);
    }

    map<T>(callbackfn: (value: Label, key: string)=>T): T[]{
        return Object.entries(this._map).map(([key, value])=>callbackfn(value, key));
    }
    
    forEach(callbackfn: (value: Label, key: string)=>void): void{
        Object.entries(this._map).forEach(([key, value])=>callbackfn(value, key));
    }

    addInstance(label: string, instance: SampleRecord): Label{
        if(!(label in this._map)){
            this._map[label] = new Label(label);
        }
        this._map[label].instances.push(instance);
        return this._map[label];
    }

    calculateKeywords(){
        this.forEach((label)=>label.calculateKeywords());
    }
}

export type RawLabelMap = {[pk: string]: Label};

export default class LabeledRecord{
    label: string;
    title: string;
    nativeStr: string;
    nativeId: NativeId;
    created: Date;
    foreignKeys: string[];

    get isRoot(){
        return this.foreignKeys.length === 0;
    }

    constructor(primaryKey: string, title: string, foriegnKeys: string[], nativeStr: string, created: string, nativeId: NativeId){
        this.label = primaryKey;
        this.foreignKeys = foriegnKeys;
        this.title = title;
        this.nativeStr = nativeStr;
        this.nativeId = nativeId;
        this.created = new Date(created);
    }



    static fromSampleList(samples: ILabeledRecord[]): LabelMap{
        let records = samples.map((sample)=>new LabeledRecord(sample.label, sample.title, sample.foreignKeys, sample.nativeStr, sample.created, sample.nativeId));

        const roots = records.filter(sample=>sample.isRoot);
        records = records.filter(sample=>!sample.isRoot);

        const labelmap: LabelMap = new LabelMap();
        
        const parents = records.reduce((parentage, record)=>{
            record.foreignKeys.forEach(parent=>{
                if(!(parent in parentage)){
                    parentage[parent] = [];
                }
                parentage[parent].push(record);
            });
            return parentage;
        }, {} as {[key: string]: LabeledRecord[]});

        const addInstance = (record: LabeledRecord, labelmap: LabelMap)=>{
            const label = labelmap.addInstance(record.label, {nativeStr: record.nativeStr, title: record.title,  created: record.created, nativeId: record.nativeId});
            parents[record.nativeStr]?.forEach((childRecord)=>{
                addInstance(childRecord, label.children);
            });
        }

        roots.forEach(root=>addInstance(root, labelmap));
        labelmap.calculateKeywords();

        console.log(labelmap);

        return labelmap;
    }

}